<template>
  <div class="page-content">
    <div class="header">
      <h5 class="title">Gestion des templates {{ selectedMenu }}</h5>
      <div class="button">
        <b-button
          size="sm"
          variant="light"
          v-if="sortDesc"
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-up" />
        </b-button>
        <b-button
          size="sm"
          variant="light"
          v-else
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-down" />
        </b-button>
        <b-button
          v-if="selectedMenu == 'e-mail'"
          size="sm"
          variant="success"
          @click.stop="initialisetinymce"
          class="searchIcon"
        >
          <font-awesome-icon icon="plus" />
          Ajouter une tempalet E-mail
        </b-button>
        <b-button
          v-if="selectedMenu == 'sms'"
          size="sm"
          variant="success"
          @click="showTemplateSms"
          class="searchIcon"
        >
          <font-awesome-icon icon="plus" /> Ajouter une template SMS
        </b-button>
      </div>
    </div>
    <div
      v-if="getSmsTemplateLoading || getMailTemplateLoading"
      class="init-loading three-dots-loading"
    >
      Chargement en cours
    </div>
    <div class="blockMenu">
      <div @click="clickMenu('e-mail')">
        <router-link class="menu-item" to="/setting/templates">
          E-mail
        </router-link>
      </div>
      <div @click="clickMenu('sms')">
        <router-link class="menu-item" to="/setting/templates/templatesSms">
          SMS
        </router-link>
      </div>
    </div>
    <hr class="lineMenu" />
    <div class="content">
      <router-view />
      <!-- Model Template Email -->
      <Modal
        v-model="showTemlplateEmail"
        modalClass="ModelClass"
        title="Ajouter une template E-mail"
      >
        <form
          ref="newTemplateEmailForm"
          @submit.prevent="handleNewEmailTemplate"
        >
          <b-row class="row mb-1">
            <!-- Title -->
            <b-col col xs="12" sm="6" md="6">
              <b-form-group
                label="Titre * "
                label-for="title-template-email-input"
              >
                <b-form-input
                  id="title-template-email-input"
                  v-model="templateEmailToAdd.title"
                  required
                ></b-form-input>
              </b-form-group>
              <!-- Objet  -->
              <b-form-group
                label="Objet * "
                label-for="title-template-email-input"
              >
                <b-form-textarea
                  class="mt-2"
                  rows="2"
                  no-resize
                  v-model="templateEmailToAdd.subject"
                  required
                ></b-form-textarea>
              </b-form-group>
              <!-- SELECT CATEGRY DES FICHIER -->
              <b-form-group label="Catégories des pièces jointes">
                <multiselect
                  class="select-categ-template"
                  v-model="templateEmailToAdd.category_files"
                  :options="subCategoryOfCategoryOptions || []"
                  placeholder="Rechercher catégorie de pièce jointe"
                  :multiple="true"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="true"
                  :allow-empty="true"
                  label="name"
                  track-by="id"
                  :preselect-first="false"
                  group-values="subcategorie"
                  group-label="categorie"
                  :group-select="true"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  selectGroupLabel=""
                  deselectGroupLabel=""
                >
                  <span slot="noResult"
                    >Aucune catégorie de pièce jointe trouvée.</span
                  >
                  <span slot="noOptions"
                    >Aucune catégorie de pièce jointe trouvée.</span
                  >
                </multiselect>
              </b-form-group>
              <b-form-group label="Adresse générique *">
                <b-form-input
                  type="email"
                  v-model="templateEmailToAdd.email"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- Fields -->
            <b-col col xs="12" sm="6" md="6">
              <div class="mb-1 titleFields">
                Champs
                <div class="CopieMsg" v-if="copierText">Text copier</div>
              </div>
              <div class="groupe-fields">
                <div
                  v-for="(prop, val) of getEmailTemplatesFields"
                  :key="prop.id"
                  @click="CopyFields({ prop: prop, val: val })"
                  class="filed-class"
                  title="Copier"
                >
                  <div class="icon-field">☰</div>
                  <div class="value-field">{{ val }}</div>
                  <div class="icon-copy"><font-awesome-icon icon="copy" /></div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="row">
            <!-- Body -->
            <b-col col xs="12" sm="12" md="12">
              <editor
                v-model="templateEmailToAdd.body"
                :init="computedDataTinymce.init"
              ></editor>
            </b-col>
          </b-row>
          <div class="message mt-2">
            <div v-if="getTemplateEmailError" class="error">
              <ul v-if="Array.isArray(getTemplateEmailError)">
                <li v-for="(e, index) in getTemplateEmailError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTemplateEmailError }}</span>
            </div>
            <div v-else-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>

            <div
              v-if="getNewMailTemplateLoading"
              class="three-dots-loading mt-2"
            >
              Chargement en cours
            </div>
          </div>
          <div class="form-actions mt-5">
            <b-button
              @click="hideModalTemplete('showTemlplateEmail')"
              variant="outline-secondary"
            >
              Annuler
            </b-button>
            <b-button variant="success" type="submit">
              Valider
            </b-button>
          </div>
        </form>
      </Modal>
      <!-- Model Template SMS  -->
      <Modal
        v-model="templateSmsShow"
        modalClass="ModelClass"
        title="Ajouter une template SMS"
      >
        <b-row class="row mb-1">
          <!-- Title -->
          <b-col col xs="12" sm="6" md="6">
            <b-form-group label="Titre * " label-for="title-template-sms-input">
              <b-form-input
                ref="title-template-sms-input"
                id="title-template-sms-input"
                v-model="templateSmsToAdd.title"
                required
              ></b-form-input>
            </b-form-group>
            <!-- Objet  -->
            <b-form-group
              label="Message * "
              label-for="title-template-sms-input"
            >
              <b-form-textarea
                id="textarea"
                v-model="templateSmsToAdd.message"
                rows="3"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <!-- Fields -->
          <b-col col xs="12" sm="6" md="6">
            <div class="mb-1 titleFields">
              Champs
              <div class="CopieMsg" v-if="copierText">Text copier</div>
            </div>
            <div
              v-for="(prop, val) of getSmsTemplatesFields"
              :key="prop.id"
              @click="CopyFields({ prop: prop, val: val })"
              class="filed-class"
              title="Copier"
            >
              <div class="icon-field">☰</div>
              <div class="value-field">{{ val }}</div>
              <div class="icon-copy">
                <font-awesome-icon icon="copy" />
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="message">
          <div v-if="getSmsTemplateLoading" class="loading">
            Chargement en cours...
          </div>
          <div v-if="getTemplateSmsError" class="error">
            <ul v-if="Array.isArray(getTemplateSmsError)">
              <li v-for="(e, index) in getTemplateSmsError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getTemplateSmsError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModalTemplete('templateSmsShow')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button
            variant="success"
            type="submit"
            @click.prevent="handleNewSmsTemplate()"
          >
            Enregistrer
          </b-button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script src="//cdn.tinymce.com/4/tinymce.min.js"></script>
<script>
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'
import Editor from '@tinymce/tinymce-vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      selectedMenu:
        this.$router.history.current.name === 'templatesSms' ? 'sms' : 'e-mail',
      templateEmailToAdd: {
        title: null,
        subject: null,
        body: null,
        specific_field: null,
        category_files: [],
        email: null
      },
      showTemlplateEmail: false,
      templateSmsShow: false,
      templateSmsToAdd: {
        title: null,
        message: null,
        specific_field: null
      },
      copierText: false,
      image_list: [],
      error: null,
      full_name: null,
      sortDesc: false,
      sort_by: 'full_name',
      sort_by_desc: 'full_name'
    }
  },

  methods: {
    ...mapActions([
      'fetchEmailTemplatesFields',
      'newEmailTemplate',
      'resetErrorEmailTemplate',
      'resetErrorSmsTemplate',
      'fetchSmsTemplatesFields',
      'newSmsTemplate',
      'setLisImage',
      'getAllImageTemplate',
      'fetchCategoriesPieceJointeFile',
      'fetchAllEmailTemplates',
      'fetchAllSmsTemplates'
    ]),
    clickMenu(menu) {
      this.selectedMenu = menu
      this.sortDesc = false
      if (this.selectedMenu == 'e-mail') {
        if (!this.sortDesc) {
          this.fetchAllEmailTemplates({
            sort_by: this.sort_by,
            full_name: this.full_name
          })
        } else {
          this.fetchAllEmailTemplates({
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name
          })
        }
      } else {
        if (!this.sortDesc) {
          this.fetchAllSmsTemplates({
            sort_by: this.sort_by,
            full_name: this.full_name
          })
        } else {
          this.fetchAllSmsTemplates({
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name
          })
        }
      }
    },
    showTemplateSms() {
      this.templateSmsShow = true
      this.templateSmsToAdd = {
        title: null,
        message: null,
        specific_field: null
      }
    },
    validateChamps(field) {
      const validate = {
        status: 'success',
        errors: [],
        show: true
      }
      if (!field.body) {
        validate.status = 'error'
        validate.errors.push('Vous devez remplir le contenu de la template')
      } else if (!field.specific_field || !field.specific_field.length) {
        validate.status = 'error'
        validate.errors.push(
          'Vous devez remplir le contenu de la template par les champs'
        )
      }
      return validate
    },
    async handleNewEmailTemplate() {
      this.error = null
      const specific_field = []
      if (this.templateEmailToAdd.body != null) {
        for (const [key, value] of Object.entries(
          this.getEmailTemplatesFields
        )) {
          if (this.templateEmailToAdd.body.indexOf(value) !== -1) {
            specific_field.push(value)
          }
        }
      }
      this.templateEmailToAdd.specific_field = specific_field
      const validate = this.validateChamps(this.templateEmailToAdd)
      if (validate.status === 'error') {
        this.error = validate.errors
        return
      }
      const body =
        '<!DOCTYPE html><html><head><meta charset="UTF-8"> <title>' +
        this.templateEmailToAdd.title +
        '</title></head><body>' +
        this.templateEmailToAdd.body +
        '</body></html>'
      const response = await this.newEmailTemplate({
        template: {
          body: body,
          title: this.templateEmailToAdd.title,
          subject: this.templateEmailToAdd.subject,
          specific_field: specific_field,
          category_files: this.templateEmailToAdd.category_files.map(
            item => item.id
          ),
          email: this.templateEmailToAdd.email
        }
      })
      if (response) {
        this.hideModalTemplete('showTemlplateEmail')
      }
    },
    async handleNewSmsTemplate() {
      if (
        this.templateSmsToAdd.title == null ||
        this.templateSmsToAdd.title == ''
      ) {
        this.$refs['title-template-sms-input'].reportValidity(
          this.$refs['title-template-sms-input'].validationMessage
        )
      } else {
        const specific_field = []
        if (this.templateSmsToAdd.message != null) {
          for (const [key, value] of Object.entries(
            this.getSmsTemplatesFields
          )) {
            if (this.templateSmsToAdd.message.indexOf(value) !== -1) {
              specific_field.push(value)
            }
          }
        }
        const response = await this.newSmsTemplate({
          template: {
            message: this.templateSmsToAdd.message,
            title: this.templateSmsToAdd.title,
            specific_field: specific_field
          }
        })
        if (response) {
          this.hideModalTemplete('templateSmsShow')
        }
      }
    },
    initialisetinymce() {
      this.showTemlplateEmail = true
      this.resetTemplateModel('showTemlplateEmail')
    },
    resetTemplateModel(template) {
      this.templateEmailToAdd = {
        title: null,
        subject: null,
        body: null,
        specific_field: null,
        category_files: [],
        email: null
      }
      this.templateSmsToAdd = {
        title: null,
        message: null,
        specific_field: null
      }
      if (template === 'showTemlplateEmail') {
        this.resetErrorEmailTemplate()
      } else {
        this.resetErrorSmsTemplate()
      }
      this.error = null
    },
    hideModalTemplete(template) {
      this[template] = false
      this.resetTemplateModel(template)
    },
    CopyFields(payload) {
      const el = document.createElement('textarea')
      el.value = payload.prop
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand('copy')
      document.body.removeChild(el)
      this.copierText = true
      setTimeout(() => {
        this.copierText = false
      }, 800)
    },
    async filter() {
      // Tester selonmenu
      if (this.selectedMenu == 'e-mail') {
        if (!this.sortDesc) {
          this.fetchAllEmailTemplates({
            sort_by: this.sort_by,
            full_name: this.full_name
          })
        } else {
          this.fetchAllEmailTemplates({
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name
          })
        }
      } else {
        if (!this.sortDesc) {
          this.fetchAllSmsTemplates({
            sort_by: this.sort_by,
            full_name: this.full_name
          })
        } else {
          this.fetchAllSmsTemplates({
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name
          })
        }
      }
    },
    sortBy() {
      this.sortDesc = !this.sortDesc
      if (this.selectedMenu == 'e-mail') {
        if (!this.sortDesc) {
          this.fetchAllEmailTemplates({
            sort_by: this.sort_by,
            full_name: this.full_name
          })
        } else {
          this.fetchAllEmailTemplates({
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name
          })
        }
      } else {
        if (!this.sortDesc) {
          this.fetchAllSmsTemplates({
            sort_by: this.sort_by,
            full_name: this.full_name
          })
        } else {
          this.fetchAllSmsTemplates({
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getEmailTemplatesFields',
      'getTemplateEmailError',
      'getNewMailTemplateLoading',
      'getSmsTemplatesFields',
      'getTemplateSmsError',
      'getSmsTemplateLoading',
      'getMailTemplateLoading',
      'getImageTemplateList',
      'getCategoriesPieceJointeFile'
    ]),
    subCategoryOfCategoryOptions: function() {
      if (
        this.getCategoriesPieceJointeFile &&
        this.getCategoriesPieceJointeFile.length
      ) {
        return this.getCategoriesPieceJointeFile.map(categorie => {
          return {
            categorie: categorie.name,
            subcategorie: categorie.subCategorie.map(subCategory => {
              return {
                name: subCategory.name,
                id: subCategory.id
              }
            })
          }
        })
      }
      return []
    },
    computedImageList() {
      return this.getImageTemplateList.map(item => {
        return { title: item.title, value: item.url }
      })
    },
    computedDataTinymce() {
      return {
        init: {
          selector: '#editor',
          init_instance_callback: function(editor) {
            var freeTiny = document.querySelector(
              '.tox-notifications-container'
            )
            freeTiny.style.display = 'none'
          },
          height: 450,
          fullpage_default_doctype: '<!DOCTYPE html>',
          fullpage_default_encoding: 'UTF-8',
          plugins: [
            'advlist autolink lists link image  print preview hr',
            'searchreplace visualblocks code ',
            'insertdatetime  table paste imagetools charmap'
          ],
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 50pt',

          toolbar_mode: 'floating',
          powerpaste_allow_local_images: true,
          language: 'fr_FR',
          image_list: success => success(this.computedImageList),
          toolbar:
            ' undo redo | bold italic underline | alignleft aligncenter alignright alignjustify |  link image |' +
            ' bullist  numlist outdent indent  |  forecolor backcolor  |' +
            '   print preview  fullpage |' +
            ' hr charmap',
          menubar: 'favs file edit view insert format   ',
          menu: {
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat '
            }
          },
          contextmenu: false,
          removed_menuitems: 'paste pastetext',
          block_formats: 'box=alert-box; badge=badge; footer=footer',
          formats: {
            'alert-box': {
              block: 'div',
              styles: {
                'background-color': '#f4f5f9',
                padding: '20px'
              }
            },
            footer: {
              block: 'div',
              styles: {
                'background-color': ' #c7c7c7',
                padding: '20px'
              }
            },
            badge: {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '.1rem solid transparent',
                'border-radius': '50px',
                padding: '1rem',
                'margin-top': '0px',
                'margin-bottom': '1rem',
                color: '#2276d2',
                'box-shadow': '2px 3px #d6d7da',
                'background-color': '#008cd6',
                color: 'white'
              }
            }
          },
          /* enable title field in the Image dialog*/
          image_title: true,
          file_picker_types: 'image',
          images_upload_handler: async (blobInfo, success, failure) => {
            let param = new FormData()
            param.append('image', blobInfo.blob())
            this.setLisImage(param)
              .then(response => {
                success(response.data.data.url)
              })
              .catch(error => {
                failure('HTTP Error: ' + error.message)
              })
          }
        }
      }
    }
  },
  components: {
    Editor,
    Modal: VueModal
  },
  mounted() {
    this.fetchEmailTemplatesFields({
      sort_by: this.sort_by
    })
    this.fetchSmsTemplatesFields({
      sort_by: this.sort_by
    })
    this.getAllImageTemplate()
    this.fetchCategoriesPieceJointeFile({ loading: true })
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    .color {
      color: #2dabe2;
    }
    .button {
      display: flex;
      .searchIcon {
        border-radius: unset;
        box-shadow: unset;
        cursor: pointer;
        border: 1px solid #6c757d59;
        &:focus {
          box-shadow: unset;
        }
        &.padd-btn {
          padding: 0px 9px;
        }
      }
    }
  }
  .blockMenu {
    display: flex;
    margin-top: 15px;
    .menu-item {
      text-decoration: none;
      color: #26292c;
      padding: 10px;
      &.router-link-exact-active {
        color: #2dabe2;
        border-bottom: 2px solid #2dabe2;
      }
    }
  }
  .lineMenu {
    margin-top: 8px;
    margin-bottom: 1rem;
  }
  .content {
    .model {
      position: absolute;
      width: 500px;
      height: 600px;
    }
  }
}

.card-column-header {
  position: relative;
  width: 100%;
  padding: 9px;
}
.column-drag-handle,
.ss-column-drag-handle {
  position: absolute;
  top: 11px;
  left: 6px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-title-stage,
.input-title-SS {
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
  input {
    color: black;
    overflow: visible;
    background-color: transparent;
    border: 0px;
    font-size: 13px;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 400px) {
  .header {
    .title {
      font-size: 15px;
    }
  }
}
</style>
<style lang="scss">
.tox-notifications-container {
  display: none !important;
}
.ModelClass {
  border-radius: 5px;
  max-width: 800px;
  width: auto;
  .vm-title {
    font-size: 1.25rem;
  }
}
.select-categ-template {
  .multiselect__option--group {
    text-align: center;
  }
}
.titleFields {
  display: flex;

  .CopieMsg {
    margin-left: 50px;
    color: #4caf50;
    font-weight: 12px;
  }
}
.groupe-fields::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}
.groupe-fields::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #80808054;
  border-radius: 10px;
}
.groupe-fields::-webkit-scrollbar-thumb {
  background: #dcdee0;
  border-radius: 10px;
}
.groupe-fields::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.groupe-fields {
  white-space: normal;
  max-height: 362px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 340px;
}
.filed-class {
  border: 1px solid #6c757d4d;
  background-color: #ebebeb5c;
  border-radius: 4px;
  position: relative;
  padding: 5px;
  width: 318px;
  margin: 4px;
  cursor: pointer;
  .icon-field {
    position: absolute;
    left: 6px;
    width: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .icon-copy {
    position: absolute;
    right: 6px;
    width: 25px;
    top: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .value-field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 25px;
  }
}
</style>
